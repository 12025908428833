import { createApp } from 'vue';
import { 
    Radio, 
    InputNumber, 
    Alert, 
    Result, 
    Row, 
    Col, 
    Anchor, 
    Divider, 
    Steps, 
    Checkbox, 
    Descriptions, 
    Card, 
    Spin, 
    Tag, 
    Input, 
    Button, 
    Form, 
    Layout, 
    Menu, 
    message, 
    Breadcrumb, 
    Table, 
    Pagination, 
    Modal, 
    Select, 
    BackTop,
    List,
    Switch,
    Tooltip,
    Badge,
    Upload,
    Tabs
} from 'ant-design-vue';
import App from './App.vue';
import router from './router';
import 'ant-design-vue/dist/antd.css';

const app = createApp(App)

// Ant Design Vue
app.use(InputNumber);
app.use(Alert);
app.use(Form);
app.use(Input);
app.use(Button);
app.use(Layout);
app.use(Menu);
app.use(Breadcrumb);
app.use(Table);
app.use(Tabs);
app.use(Pagination);
app.use(Tag);
app.use(Spin);
app.use(Card);
app.use(Descriptions);
app.use(Checkbox);
app.use(Steps);
app.use(Divider);
app.use(Anchor);
app.use(Row);
app.use(Col);
app.use(Result);
app.use(Modal);
app.use(Select);
app.use(Radio);
app.use(BackTop);
app.use(List);
app.use(Switch);
app.use(Tooltip);
app.use(Badge);
app.use(Upload);

app.config.globalProperties.$message = message;

// Mount router
app.use(router);
app.mount('#app')
