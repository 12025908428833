import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';

let routes: Array<RouteRecordRaw> = [
  // Fallback 404 page
  {
    path: '/:pathMatch(.*)*',
    name: '404',
    component: () => import('./views/fourohfour.vue'),
    meta: {
      title: 'AEON Middleware Boxed | 404 Not Found',
      layout: 'authentication'
    }
  }
]

// Concat new routes
import applicationRoutes from './routes/application';

routes = routes.concat(applicationRoutes);

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

router.beforeEach((to, from, next) => {
  // For pre-request hooks
  if (to.meta.authenticationRequired) {
    const token = window.localStorage.getItem(process.env.VUE_APP_ACCESS_TOKEN_IDENTIFIER!);
    if (token === null || token === undefined || token.length <= 0) {
      return next('/');
    }
  }

  next();
});

router.afterEach((to, from) => {
  // In case we need an "after" request hook
});

export default router;
