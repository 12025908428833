import { RouteRecordRaw } from 'vue-router';

const routes: Array<RouteRecordRaw> = [
    {
        path: '/',
        name: 'login',
        component: () => import('../views/login.vue'),
        meta: {
            layout: 'authentication',
        }
    },
    {
        path: '/login',
        component: () => import('../views/login.vue'),
        meta: {
            layout: 'authentication',
        }
    },
    {
        path: '/extension-table',
        name: 'extension_table',
        component: () => import('../views/extension-table.vue'),
        meta: {
            layout: 'application',
            authenticationRequired: true,
            selectedKey: '1',
        }
    },
    {
        path: '/amy-category-mapping',
        name: 'amy_category_mapping',
        component: () => import('../views/amy-category-mapping.vue'),
        meta: {
            layout: 'application',
            authenticationRequired: true,
            selectedKey: '2',
        }
    },
    {
        path: '/abg-category-mapping',
        name: 'abg_category_mapping',
        component: () => import('../views/abg-category-mapping.vue'),
        meta: {
            layout: 'application',
            authenticationRequired: true,
            selectedKey: '3',
        }
    },
    {
        path: '/import-tasks',
        name: 'import_tasks',
        component: () => import('../views/import-tasks.vue'),
        meta: {
            layout: 'application',
            authenticationRequired: true,
            selectedKey: '4',
        }
    },
    {
        path: '/e-shop-status',
        name: 'e_shop_status',
        component: () => import('../views/e-shop-status.vue'),
        meta: {
            layout: 'application',
            authenticationRequired: true,
            selectedKey: '5',
        }
    },
    {
        path: '/health-check',
        name: 'health_check',
        component: () => import('../views/health-check.vue'),
        meta: {
            layout: 'application',
            authenticationRequired: true,
            selectedKey: '6',
        }
    },
    {
        path: '/boxed-price-lock',
        name: 'boxed_price_lock',
        component: () => import('../views/boxed-price-lock.vue'),
        meta: {
            layout: 'application',
            authenticationRequired: true,
            selectedKey: '7',
        }
    },
    {
        path: '/boxed-category-list',
        name: 'boxed_category_list',
        component: () => import('../views/boxed-category-list.vue'),
        meta: {
            layout: 'application',
            authenticationRequired: true,
            selectedKey: '8',
        }
    }
];

export default routes;
