
import { MenuUnfoldOutlined, DollarCircleOutlined, MenuFoldOutlined, BarcodeOutlined, LogoutOutlined, FileImageOutlined, ReconciliationOutlined, SettingOutlined, OrderedListOutlined } from '@ant-design/icons-vue';
import { Options, Vue } from 'vue-class-component';
import router from './router';

@Options({
  components: {
    'menu-unfold-outlined': MenuUnfoldOutlined,
    'menu-fold-outlined': MenuFoldOutlined,
    'extension-table-outlined': OrderedListOutlined,
    'orders-outlined': ReconciliationOutlined,
    'products-outlined': BarcodeOutlined,
    'logout-outlined': LogoutOutlined,
    'banners-outlined': FileImageOutlined,
    'payout-outlined': DollarCircleOutlined,
    'settings-outlined': SettingOutlined,
  }
})
export default class App extends Vue {
  defaultLayout = 'authentication';
  collapsed = false;

  get selectedKeys() {
    const key = this['$route'].meta.selectedKey;
    const array = new Array();
    array.push(key);
    return array;
  }

  get layout() {
    return this['$route'].meta.layout || this.defaultLayout;
  }

  logout() {
    if (confirm('Are you sure you want to log out?')) {
      router.push({
        name: 'login'
      });
      window.localStorage.removeItem(process.env.VUE_APP_ACCESS_TOKEN_IDENTIFIER!);
    }
  }

  openLink(linkName: string) {
    try {
      router.push({
        name: linkName
      });
    } catch (error) {
      router.push({
       path: '/404'
      });
    }
  }

  mounted() {
    console.log('[APP] Mounted Application');
  }
}
